import React, {useEffect, useState} from 'react';
import axios from 'axios';
import './App.css';
import * as jose from 'jose';
import {Buffer} from "buffer";
import loader from './imgs/loader.png'
import * as Sentry from "@sentry/browser";


window.Buffer = window.Buffer || require("buffer").Buffer;

export default function App() {
    // SET CONSTANTS
    const API_URL_GET_JOIN_LINK = "https://webexapis.com/v1/meetings/join"
    const API_URL_EXCHANGE_GUEST_TOKEN = "https://webexapis.com/v1/jwt/login"
    // CASE: GUEST JOIN PERSONAL ROOM WITHOUT PASSWORD
    const GUEST_FALLBACK_PASSWORD = "password"
    const GUEST_ROOM_DEFAULT_NAME = process.env.REACT_APP_GUEST_ROOM_DEFAULT_NAME
    const GUEST_ISSUER_ID = process.env.REACT_APP_GUEST_ISSUER_ID
    const GUEST_ISSUER_SHARED_SECRET = process.env.REACT_APP_GUEST_ISSUER_SHARED_SECRET

    // SET VARIABLES
    const queryParams = new URLSearchParams(window.location.search)
    let tokenQp = queryParams.get('token')
    const [token] = useState(tokenQp)
    const [isGuest] = useState(!tokenQp || tokenQp.trim() === "")
    const [destination] = useState(queryParams.get('destination')) || null
    const [password] = useState(queryParams.get('password')) || null
    const [roomName] = useState(queryParams.get('roomName') || GUEST_ROOM_DEFAULT_NAME)
    const [meetingDataError, setMeetingDataError] = useState(null)
    const [desktop] = useState(queryParams.get('desktop') === 'true') || false

    // METHOD TO GENERATE RANDOM GUEST UUID
    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    }

    function webexFailWorkaround(destination) {
        if (destination.startsWith("http")) {
            destination = destination + "&autojoin=true&roomname=" + encodeURIComponent(roomName);
            if (!desktop) {
                destination = destination + "&launchApp=true";
            }
            window.location.replace(destination)
        } else {
            setMeetingDataError("Could not join the meeting. The meeting password is not correct or the meeting may not accept guests.")
        }
    }

    async function generateSignedGuestJwt() {
        const sub = "mago-room-guest-" + uuidv4()
        return await new jose.SignJWT({
            "name": roomName,
            "sub": sub,
            "iss": GUEST_ISSUER_ID,
            "exp": Math.floor(Date.now() / 1000) + 86400
        })
            .setProtectedHeader({typ: 'JWT', alg: 'HS256'})
            .setIssuedAt()
            .setIssuer(GUEST_ISSUER_ID)
            .setSubject(sub)
            .setExpirationTime('24h')
            .sign(Buffer.from(GUEST_ISSUER_SHARED_SECRET, 'base64'))
    }

    function getMeetingJoinLink(destination, authToken) {
        //SET POST DATA
        let data = {
            "joinDirectly": false,
            "email": process.env.REACT_APP_GUEST_ROOM_DEFAULT_EMAIL,
            "displayName": roomName
        }
        if (password) {
            data["password"] = password
        } else {
            if (isGuest) {
                data["password"] = GUEST_FALLBACK_PASSWORD
            }
        }
        if (destination.startsWith("http")) {
            data["webLink"] = destination
        } else {
            data["meetingNumber"] = destination
        }

        //SET POST HEADERS
        let headers = {
            Authorization: "Bearer " + authToken
        }
        if (password) {
            headers["password"] = password
        } else {
            if (isGuest) {
                headers["password"] = GUEST_FALLBACK_PASSWORD
            }
        }
        // GET MEETING JOIN DIRECT LINK
        axios.post(`${API_URL_GET_JOIN_LINK}`, data, {
            headers: headers
        }).then(res => {
            if (res.data.hasOwnProperty("startLink")) {
                let link = res.data.startLink + "&autojoin=true&roomname=" + encodeURIComponent(roomName);
                if (!desktop) {
                    link = link + "&launchApp=true";
                }
                window.location.replace(link)
            } else if (res.data.hasOwnProperty("joinLink")) {
                let link = res.data.joinLink + "&autojoin=true&roomname=" + encodeURIComponent(roomName);
                if (!desktop) {
                    link = link + "&launchApp=true";
                }
                window.location.replace(link)
            } else {
                setMeetingDataError("Could not join the meeting. The meeting may have already ended or may have been cancelled.")
            }
        }).catch(error => {
            Sentry.captureException(error);
            webexFailWorkaround(destination)
        })
    }

    useEffect(() => {
        // IF TOKEN IS MISSING, HANDLE THE REQUEST AS A GUEST
        if (destination) {
            if (isGuest) {
                generateSignedGuestJwt().then((guestJwtToken) => {
                    axios.post(`${API_URL_EXCHANGE_GUEST_TOKEN}`, {}, {
                        headers: {
                            Authorization: `Bearer ${guestJwtToken}`,
                        }
                    }).then((res) => {
                        getMeetingJoinLink(destination, res.data.token)
                    }).catch(e => {
                        Sentry.captureException(e);
                        webexFailWorkaround(destination)
                    })
                })
            } else {
                getMeetingJoinLink(destination, token)
            }
        }
    }, [])

    // CHECK FOR "destination" PARAM (required)
    // MUST BE A VALID ENCODED WEBLINK (e.g. https%3A%2F%2Fmeet80.webex.com%2Fmeet80%2Fj.php%3FMTID%3Dm075055f2bf0f121f9af1c880740450af)
    if (!destination) {
        return (
            <div className="content"><p className="text">Invalid URL. Missing parameters (destination). Please check the
                URL and try again.</p></div>)
    }

    if (meetingDataError) {
        return <div className="content"><p className="text">
            {meetingDataError}
        </p>
        </div>
    }

    return <div className="content"><p className="text"><img src={loader} className="loader" alt="Loading"/>Loading...
    </p></div>
}